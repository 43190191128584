import validator from 'validator';

export const isValidMaskId = id => {
  return (
    isNumeric(id) && Number(id) >= 0 && Number(id) < 16384 && !isMinusZero(id)
  );
};

const isNumeric = n => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

function isMinusZero(value) {
  return 1 / value === -Infinity;
}

export const generateDataToSign = params => {
  let result = '';
  Object.entries(params).forEach(([key, value]) => {
    result += `${key}:${value}`;
  });
  return result;
};

export const collectionInfoValidate = (title, description) => {
  if (
    !validator.isAlphanumeric(title, 'en-US', {
      ignore: ' ',
    })
  ) {
    alert('The title can only contain letters and numbers');
    return false;
  }
  if (!validator.isLength(title, { min: 1, max: 25 })) {
    alert("The title's length should be between 1 to 25");
    return false;
  }
  if (
    !validator.isAlphanumeric(description, 'en-US', {
      ignore: " ,.!\n?'-;:()",
    })
  ) {
    alert(
      'The description can only contain letters, numbers and punctuation marks',
    );
    return false;
  }
  if (!validator.isLength(description, { min: 10, max: 600 })) {
    alert("The description's length should be between 10 to 600");
    return false;
  }
  return true;
};
