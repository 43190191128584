export const PROJECT_ID = 'd40ffa0911094bcf8eefc0e1b551fc63';
export const NAME_CHANGE_PRICE = '1830000000000000000000';
export const STARTING_INDEX = 10141;
export const TOTAL_HASHMASKS = 16384;
export const HASHMASKS_URL =
  'https://hashmasknames.blob.core.windows.net/hashmasknames/names.json';
export const HASHMASKS_THUMBS_URL =
  'https://hashmasksstore.blob.core.windows.net/hashmasksthumbs/';
export const HASHMASKS_NAME_HISTORY_URL_PREFIX =
  'https://hashmasknames.blob.core.windows.net/hashmaskhistory/';
export const OPENSEA_URL = 'https://api.opensea.io/api/v1/';
export const HASHMASKS_COLLECTION_URL =
  'https://hashmasks-collections.westeurope.cloudapp.azure.com/public/';
export const GRAVEYARD_ADDRESS = '0x000000000000000000000000000000000000dead';
export const SIGNING_URL = 'https://api.fancyrats.io/hashmasks/presign';
export const ELEMENTALS_PREVIEW_URL =
  'https://api.fancyrats.io/hashmasks/elementals/preview';
export const RPC_URL =
  'https://mainnet.infura.io/v3/42ec8848724e4b0e9c61aee67ba4fd9e';
export const HASHMASKS_CONTRACT_ADDRESS =
  '0xC2C747E0F7004F9E8817Db2ca4997657a7746928';
export const NCT_CONTRACT_ADDRESS =
  '0x8A9c4dfe8b9D8962B31e4e16F8321C44d48e246E';
export const DOUBLEDROP_CONTRACT_ADDRESS =
  '0xa65178961d63964f2ac2515ca243175ad440f927';
export const ELEMENTALS_CONTRACT_ADDRESS =
  '0x8E8e573399db50E229a3058922DaCacFcD0c8Ff3';
export const DERIVATIVES_CONTRACT_ADDRESS =
  '0x58b671Fb6a3A366c376628B71BDA60c019d757A7';
