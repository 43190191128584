<template>
  <button
    v-on:click="clickAction"
    class="mint-button"
    v-bind:disabled="!buyEnabled"
  >
    <div v-if="buyEnabled === true">
      {{ buttonText }}
    </div>
    <div v-else>
      <div class="loading-circle"></div>
      <span class="pending-text">Transaction Pending</span>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    buyEnabled: { required: true, type: Boolean },
    clickAction: { required: true, type: Function },
    buttonText: { required: true, type: String },
  },
};
</script>
