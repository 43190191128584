import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store/index';
import { isValidMaskId } from '../helpers/utils';
import { GRAVEYARD_ADDRESS } from '../assets/constants';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () =>
      import(/* webpackChunkName: "Wallet" */ '../views/Wallet.vue'),
    meta: {
      allowWithoutWallet: false,
    },
  },
  {
    path: '/nct',
    name: 'nct',
    component: () =>
      import(/* webpackChunkName: "Detail" */ '../views/NCT.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: () =>
      import(/* webpackChunkName: "Detail" */ '../views/Detail.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/owner/:id',
    name: 'owner',
    component: () =>
      import(/* webpackChunkName: "Detail" */ '../views/Owner.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/overview',
    name: 'overview',
    component: () =>
      import(/* webpackChunkName: "Overview" */ '../views/Overview.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/collectionCreate',
    name: 'collectionCreate',
    component: () =>
      import(
        /* webpackChunkName: "Collection" */ '../views/CollectionCreate.vue'
      ),
    meta: {
      allowWithoutWallet: false,
    },
  },
  {
    path: '/collections',
    name: 'collections',
    component: () =>
      import(/* webpackChunkName: "Collections" */ '../views/Collections.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/collection/:id',
    name: 'collection',
    component: () =>
      import(/* webpackChunkName: "Collections" */ '../views/Collection.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () =>
      import(/* webpackChunkName: "Gallery" */ '../views/Gallery.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/names',
    name: 'names',
    component: () =>
      import(/* webpackChunkName: "Names" */ '../views/Names.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/attributes',
    name: 'attributes',
    component: () =>
      import(/* webpackChunkName: "Attributes" */ '../views/Attributes.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/no-wallet',
    name: 'no-wallet',
    component: () =>
      import(/* webpackChunkName: "NoWallet" */ '../views/NoWallet.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/wallet-history',
    name: 'wallet-history',
    component: () =>
      import(
        /* webpackChunkName: "WalletHistory" */ '../views/WalletHistory.vue'
      ),
    meta: {
      allowWithoutWallet: false,
    },
  },
  {
    path: '/manifesto',
    name: 'manifesto',
    component: () =>
      import(/* webpackChunkName: "NoWallet" */ '../views/Manifesto.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: () =>
      import(/* webpackChunkName: "Terms" */ '../views/Terms.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/doubledrop-terms',
    name: 'doubledrop-terms',
    component: () =>
      import(/* webpackChunkName: "Terms" */ '../views/DoubleDropTerms.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: () =>
      import(/* webpackChunkName: "Disclaimer" */ '../views/Disclaimer.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () =>
      import(/* webpackChunkName: "Disclaimer" */ '../views/FAQ.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/community-tools',
    name: 'community-tools',
    component: () =>
      import(/* webpackChunkName: "Communiity" */ '../views/Community.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () =>
      import(/* webpackChunkName: "Disclaimer" */ '../views/Privacy.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/grants',
    name: 'grants',
    component: () =>
      import(/* webpackChunkName: "Grants" */ '../views/Grants.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/elementals-rarity',
    name: 'elementals-rarity',
    component: () =>
      import(/* webpackChunkName: "Grants" */ '../views/ElementalRarities.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () =>
      import(/* webpackChunkName: "Analytics" */ '../views/NamesChart.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
  {
    path: '/doubledrop',
    name: 'doubledrop',
    component: () =>
      import(/* webpackChunkName: "PFP" */ '../views/DoubleDrop.vue'),
    meta: {
      allowWithoutWallet: false,
    },
  },
  {
    path: '/graveyard',
    name: 'graveyard',
    component: () =>
      import(/* webpackChunkName: "PFP" */ '../views/Graveyard.vue'),
    meta: {
      allowWithoutWallet: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  NProgress.set(0.1);
  if (to.name == 'detail' && isValidMaskId(to.params.id) === false) {
    next({
      path: '/404',
    });
  }
  if (to.name == 'owner' && to.params.id.toLowerCase() === GRAVEYARD_ADDRESS) {
    next({
      path: '/graveyard',
    });
  }
  if (!to.meta.allowWithoutWallet && !store.state.isWalletConnected) {
    next({
      path: '/no-wallet',
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
