import Vue from 'vue';
import Vuex from 'vuex';
import VueNoty from 'vuejs-noty';
import 'vuejs-noty/dist/vuejs-noty.css';
import { ethers } from 'ethers';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);
Vue.use(VueNoty, {
  theme: 'relax',
  layout: 'centerRight',
  timeout: false,
});

const initialState = {
  pendingTx: [],
  completedTx: [],
  isWalletConnected: false,
  defaultAccount: null,
};

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['pendingTx', 'completedTx'],
    }),
  ],
  state: JSON.parse(JSON.stringify(initialState)),
  getters: {
    getTotalSupply(state) {
      return state.totalSupply;
    },
    getPendingTx(state) {
      return state.pendingTx;
    },
  },
  mutations: {
    async setWalletWeb3(state) {
      if (window.ethereum) {
        const walletProvider = new ethers.providers.Web3Provider(
          window.ethereum,
        );
        const defaultAccount = await walletProvider.getSigner().getAddress();
        if (defaultAccount) {
          Vue.set(state, 'isWalletConnected', true);
        }
      }
    },
    addPendingTx(state, { txHash, description }) {
      const txDetails = {
        txHash,
        description,
        status: 0,
      };
      state.pendingTx.push(txDetails);
      Vue.noty.info(
        "<img src='/images/clock-icon.png' style='width: 40px;'>  Transaction initiated for " +
          description,
      );
    },
    setDefaultAccount(state, address) {
      state.defaultAccount = address;
    },
    removePendingTx(state, txHash) {
      state.pendingTx = state.pendingTx.filter(
        (item) => item.txHash !== txHash,
      );
    },
    removeCompletedTx(state, txHash) {
      state.completedTx = state.completedTx.filter(
        (item) => item.txHash !== txHash,
      );
    },
    completeTx(state, { txDetails, status }) {
      state.pendingTx = state.pendingTx.filter(
        (item) => item.txHash !== txDetails.txHash,
      );
      if (status === 1) {
        Vue.noty.success(
          "<img src='/images/success-icon.png' style='width: 40px;'> Transaction successful for " +
            txDetails.description,
        );
        const audio = new Audio('/sound/swiftly.mp3');
        audio.play();
      } else {
        Vue.noty.error(
          "<img src='/images/error-soft-icon.png' style='width: 40px;'> Transaction reverted for " +
            txDetails.description,
        );
      }
      txDetails.status = status;
      state.completedTx.push(txDetails);
    },
  },
  methods: {
    ...Vuex.mapMutations([
      'addPendingTx',
      'completeTx',
      'removePendingTx',
      'removeCompletedTx',
    ]),
  },
  modules: {},
});
