<template>
  <div class="container">
    <footer id="footer">
      <div class="row">
        <div class="col-xs-12 col-md-4 copyrights">
          <p>&COPY; 2021 Suum Cuique Labs GmbH</p>
        </div>
        <div class="col-xs-12 col-md-8 footer-socials">
          <a href="/terms">Terms and Conditions</a>
          <a href="/grants">Grant Program Terms</a>
          <a href="/faq">FAQ</a>
          <a href="mailto:mail@thehashmasks.com">Contact Us</a>
          <a href="/disclaimer">Disclaimer</a>
          <a href="https://discord.gg/crJmxxzGuZ"
            ><img class="footer-social-icon" src="/images/discord.png"
          /></a>
          <a href="https://twitter.com/TheHashmasks"
            ><img class="footer-social-icon" src="/images/twitter.png"
          /></a>
          <a href="https://medium.com/@thehashmasks"
            ><img class="footer-social-icon" src="/images/medium.png"
          /></a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style></style>
