<template>
  <div id="app">
    <Header />
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view />
    <CustomFooter />
    <span class="scrolltotop"><i class="ion-ios-arrow-thin-up"></i></span>
  </div>
</template>

<script>
import Header from '@/components/Header';
import CustomFooter from '@/components/Footer';

export default {
  name: 'App',
  components: {
    Header,
    CustomFooter,
  },
};
</script>
<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
