import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import LoadingButtonContent from '@/components/LoadingButtonContent';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
  components: {
    LoadingButtonContent: LoadingButtonContent,
  },
}).$mount('#app');
