<template>
  <div id="content-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-5">
              <div class="frame-overlay">
                <img src="/images/nftframel.png" class="empty-frame" />
                <span v-if="totalSupply < 16384" class="frame-text"
                  >#{{ totalSupply }} / 16384</span
                >
                <p v-if="totalSupply < 16384" style="text-align: center">
                  {{ remainingAtCurrentPrice }} left at this price
                </p>
                <p v-else style="text-align: center">
                  <a href="https://opensea.io/collection/hashmasks"
                    >Get a Hashmask on Opensea</a
                  >
                </p>
              </div>
            </div>
            <div class="col-sm-7">
              <div class="page-title" style="padding: 20px; padding-top: 45px">
                <h1>Hashmasks<br /></h1>
                <h5>Become part of digital art and collectibles history</h5>
                <p class="sale-section">
                  Hashmasks is a living digital art collectible. It is a collection of 16,384 unique
                  digital portraits. Brought to you by Suum Cuique Labs from
                  Zug, Switzerland.<br /><br />By holding the artwork, you
                  accumulate the NCT token on a daily basis, which allows you to
                  choose a name for your portrait on the Ethereum blockchain.
                  This is your opportunity to be among the first to participate
                  in one of the largest collaborative NFT art projects in
                  existence. <br /><br />
                  <a href="/gallery">
                    <button class="mint-button" disabled="disabled">
                      <div>
                        <span class="pending-text">Gallery Page</span>
                      </div>
                    </button>
                  </a>
                  <a href="/community-tools">
                    <button class="mint-button" disabled="disabled">
                      <div>
                        <span class="pending-text">Community Tools</span>
                      </div>
                    </button>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div class="home-subtext">
            <p style="text-align: center">
              Lives on the Ethereum Blockchain and hosted on IPFS (<a
                href="/provenance.html"
                target="_blank"
                >Record and Proof</a
              >).
            </p>
          </div>
          <div class="progress" style="margin-top: 40px; margin-left: 50px">
            <div
              class="progress-bar"
              role="progressbar"
              v-for="(nftValue, index) in nftValArray"
              :key="index"
              :class="'inactive-progress'"
              v-bind:style="[
                { borderRight: '3px solid grey' },
                styles(nftValue.width),
                gradientStyles(index),
              ]"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ nftValue.usd }} ETH
              <span>{{ nftValue.nftVal }} NFTs</span>
            </div>
          </div>
          <!-- Portfolio Wrap -->
          <div class="portfolio-wrap" style="text-align: center">
            <img
              src="https://hashmasksstore.blob.core.windows.net/hashmasksthumbs/14637.png"
              style="width: 250px"
            />
            <img
              src="https://hashmasksstore.blob.core.windows.net/hashmasksthumbs/6.png"
              style="width: 250px"
            />
            <img
              src="https://hashmasksstore.blob.core.windows.net/hashmasksthumbs/12054.png"
              style="width: 250px"
            />
            <img
              src="https://hashmasksstore.blob.core.windows.net/hashmasksthumbs/13051.png"
              style="width: 250px"
            />
          </div>

          <br />
          <blockquote>
            As the rightful owner of a Hashmasks NFT, you are granted an
            unlimited, worldwide, exclusive, license to use, copy, and display
            the purchased art for commercial use. Examples of such commercial
            Use would e.g. be the use of the Art to produce and sell merchandise
            products.<br /><br />
            If you would like to use a Hashmask, which you do not own, for
            commercial use, you need to require written consent from the current
            owner.
          </blockquote>

          <h2 style="margin-top: 60px">
            The Next Generation of Digital Art Collectibles
          </h2>
          <p>
            Hashmasks introduces a combination of digital art and collectibles
            with a value hierarchy determined by both the creator and the
            consumer of the artwork. Unlike other collectibles, where the rarity
            of all traits is set out by the creators, Hashmasks have included
            two layers of scarcity: It provides a set of traits to provide
            general guidance to the consumer, but it has also created many
            implicit traits not accounted for. Above all, the control over the
            rarest of all traits – the name – is given to the full extent to the
            consumer.
          </p>

          <p>
            Naming is commoditized via the Name Change Token (NCT), which can be
            interchanged with other participants of the artwork. Each name is
            unique and can only belong to one NFT. Hashmasks is the first
            collectible where the value and rarity of each piece within the
            collection is not predetermined, but will change and adapt over many
            years before it takes its final form when the last NCT is burnt.
            <a href="/manifesto">Read Manifesto</a>.
          </p>

          <div class="row" style="margin-top: 80px; margin-bottom: 50px">
            <div class="col-lg-4 home-icon">
              <img src="../../public/images/icon-1.png" />

              <h6>
                First collectible with several layers of scarcity determined by
                the consumer
              </h6>
            </div>
            <div class="col-lg-4 home-icon">
              <img src="../../public/images/icon-2.png" />
              <h6>Art that shaped NFT art history</h6>
            </div>
            <div class="col-lg-4 home-icon">
              <img src="../../public/images/icon-3.png" />

              <h6>Random distribution for full fairness</h6>
            </div>
            <div class="col-lg-4 home-icon">
              <img src="../../public/images/icon-4.png" />

              <h6>
                Full ownership and unlimited commercial usage rights given to
                the consumer over their NFT.
              </h6>
            </div>
            <div class="col-lg-4 home-icon">
              <img src="../../public/images/icon-5.png" />
              <h6>
                Naming becomes a scarce commodity via the NCT token mechanism
              </h6>
            </div>
            <!-- /.col-lg-4 -->
            <div class="col-lg-4 home-icon">
              <img src="../../public/images/icon-6.png" />

              <h6>Collectible, game and – above all – art</h6>
            </div>
            <!-- /.col-lg-4 -->
            <!-- /.col-lg-4 -->
          </div>

          <h2>How it works</h2>
          <p>
            There is a total of 16,384 unique Hashmasks. Each of them is created
            by a combination of several different artists. They have varying
            masks, eye colors, items and other attributes. Which means: All
            Hashmasks are rare, but some are rarer than others.
          </p>
          <p>
            To ensure a fair distribution, the user will not know what Hashmask
            they purchase for the first 14 days of the sale. All NFTs are
            already generated, but not indexed yet. There is a
            <a href="/provenance.html" target="_blank"
              >predetermined sequence of Hashmasks</a
            >, but which one will be the first Hashmask will only be determined
            at the end of the sale using a
            <a href="/faq" target="_blank">random mechanism on-chain</a>. This
            method guarantees a fair distribution. Due to expected high demand
            during the initial distribution period, we decided to limit a
            maximum of 20 Hashmasks per transaction.
          </p>
          <p>
            The distribution period begins at 16:00 on 28 January 2021 (CEST).
            The Hashmasks are being sold according to the schedule below
            (Subject to possibility of price slip as
            <a href="/faq">described in FAQ</a>). After 14 days or when all the
            Hashmasks are sold (whichever is earlier), the contribution period
            concludes and your Hashmasks will be revealed. If unsold after the
            reveal phase, The sale will continue at the prevailing rate with two
            differences: (1) All portraits will be revealed, so you know which
            one you are buying and (2) there will be no bonus NCT once the 14
            days are over.
          </p>

          <div class="row">
            <div class="col-md-6">
              <img src="../../public/images/timeline-graphic.png" />
            </div>
            <div class="col-md-6">
              <img src="../../public/images/price-table.png" />
            </div>
          </div>

          <h2>Immortalize yourself on-chain</h2>
          <p>
            You are not simply buying a piece of rare art. You are part of the
            art piece. The smart contracts allow you to assign a name to your
            Hashmask giving you the ability to add your unique stroke to the
            canvas. Thus, you become one of the founding creators that shaped
            this raw canvas into a timeless work of art. Your NFT can serve as
            your digital identity as we shift towards a fully digital economy.
          </p>

          <h2>Introducing the NCT</h2>
          <p>
            Each day, 10 Name Change Tokens (NCTs) are accumulated by each
            Hashmask and can be claimed by the current holder. When you have
            1,830 NCTs (about ½ years worth of NCTs), you can burn these tokens
            and change the name of your Hashmask on-chain. The smart contracts
            require each name to be unique, thus, creating a second layer of
            scarcity within the artwork. After exactly 10 years, on 26 January
            2031 (Timestamp: 1927206000), the last NCT will be emitted and from
            then on, the supply will only decrease until one day, the last NCT
            token is burnt and the art piece is complete.
          </p>

          <p>
            Any participant during the first 14 days of the contribution period
            will receive 1 years worth of NCTs (3,660 NCTs, the equivalent of 2
            name changes). After the 14 days, each Hashmask will start with
            enough supply for a single name change (1,830 NCTs).
          </p>
          <h2>Verified Smart Contracts</h2>
          <p class="ethereum-address-block">
            <b>Hashmasks Contract: </b
            ><a
              href="https://etherscan.io/address/0xc2c747e0f7004f9e8817db2ca4997657a7746928"
              target="_blank"
              rel="noreferrer"
              >0xc2c747e0f7004f9e8817db2ca4997657a7746928</a
            >
          </p>
          <p class="ethereum-address-block">
            <b>NameChangeToken Contract: </b>
            <a
              href="https://etherscan.io/address/0x8a9c4dfe8b9d8962b31e4e16f8321c44d48e246e"
              target="_blank"
              rel="noreferrer"
              >0x8a9c4dfe8b9d8962b31e4e16f8321c44d48e246e</a
            >
          </p>
          <p class="ethereum-address-block">
            <b>Hashmasks Registry Contract: </b>
            <a
              href="https://etherscan.io/address/0x185c8078285a3de3ec9a2c203ad12853f03c462d"
              target="_blank"
              rel="noreferrer"
              >0x185c8078285a3de3ec9a2c203ad12853f03c462d</a
            >
          </p>
          <p class="ethereum-address-block">
            <b>Hashmasks Datastore Contract: </b>
            <a
              href="https://etherscan.io/address/0x7327DbF06b1FcB0D190533fDD244B52361f0d241"
              target="_blank"
              rel="noreferrer"
              >0x7327DbF06b1FcB0D190533fDD244B52361f0d241</a
            >
          </p>
          <!-- Portfolio Wrap Ends -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import vmodal from 'vue-js-modal';

Vue.use(vmodal);

export default {
  name: 'Home',
  data() {
    return {
      currentAddress: null,
      remainingAtCurrentPrice: 10,
      saleStart: '28 Jan 2021 15:00:00 GMT',
      saleStarted: false,
      progressBarValues: [],
      buyEnabled: true,
      qtyToBuy: 1,
      validationResult: '',
      totalSupply: 0,
      checkedTerms: false,
      checkedCountry: false,
      nftValArray: [
        {
          nftVal: 3000,
          usd: 0.1,
          width: 16,
        },
        {
          nftVal: 4000,
          usd: 0.3,
          width: 20,
        },
        {
          nftVal: 4000,
          usd: 0.5,
          width: 20,
        },
        {
          nftVal: 4000,
          usd: 0.9,
          width: 20,
        },
        {
          nftVal: 1000,
          usd: 1.7,
          width: 12,
        },
        {
          nftVal: 381,
          usd: 3.0,
          width: 8,
        },
        {
          nftVal: 3,
          usd: 100,
          width: 4,
        },
      ],
    };
  },
  async mounted() {
    this.saleStarted = Number(new Date().getTime() / 1000) >= 1611846000;
    await this.updatePriceAndSupply();
    this.timer = setInterval(this.updatePriceAndSupply, 60000);
  },
  watch: {
    totalSupply(newVal) {
      let tempTotalSupply = newVal;
      let arr = [];
      for (let i of this.nftValArray) {
        if (tempTotalSupply > i.nftVal) {
          arr.push(100);
          tempTotalSupply -= i.nftVal;
        } else {
          arr.push((tempTotalSupply / i.nftVal) * 100);
          tempTotalSupply = 0;
        }
      }
      this.progressBarValues = arr;
    },
  },
  methods: {
    async updatePriceAndSupply() {
      this.totalSupply = 16384;
      let remainingSupply = this.totalSupply;
      for (let obj of this.nftValArray) {
        if (remainingSupply >= obj.nftVal) {
          remainingSupply -= obj.nftVal;
        } else {
          this.remainingAtCurrentPrice = obj.nftVal - remainingSupply;
          break;
        }
      }
    },
    gradientStyles: function(index) {
      let val = this.progressBarValues[index];

      return {
        background:
          'linear-gradient(to right, orange 0%,orange ' +
          val +
          '%,#f6f6f6 ' +
          val +
          '%,#f6f6f6 100%',
      };
    },
    styles: function(id) {
      return {
        width: id + '%',
      };
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
/* [aria-valuenow="20"] {
  color: red !important;
} */
.active-progress {
  background: orange;
}
.inactive-progress {
  background: #dddddd;
  color: gray;
}
</style>
